














































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import CommentBlock from './components/CommentBlock.vue'

@Component({
  components: {
    InfoBlock,
    CommentBlock
  },
})
export default class DeliveryPlatform extends Vue {
  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      // 'https://spics.wantu.cn/74c5bd31ef0c82cddd75ae0433efd54b.png',
      'https://spics.inctrip.com/0e7e1a7d5ef76718f34bd8f514204181.png',
      // 'https://spics.wantu.cn/854de40931f8cec8c5c73ff0a311651b.png',
      'https://spics.inctrip.com/e94ec6567f203a20d1953922f3793235.png',
      // 'https://spics.wantu.cn/5c59051e9f9bb35b9cdec6494c7b5822.png',
      'https://spics.inctrip.com/a58e0056c2362fd4c7f5985e2d831946.png',
      // 'https://spics.wantu.cn/d4b0790415362533bb80b9aea29e55ed.png',
      // 'https://spics.wantu.cn/5c32afe2706324856aff407b3baf8b46.png',
    ]
  }
}
